import { queryOptions, useQuery } from '@tanstack/react-query'
import {
  fetchCMSCatNavData,
  fetchCMSData,
  fetchDeferredHydration,
  fetchOrderPlacedCMSShelves,
  fetchOrderPlacedStoreCarousel,
  fetchZeroResultsCMSShelves,
} from '@/features/cms/services/CMS/fetchers'
import { type StoreParams } from '@/features/shared/utils/dataFetching/storeParams'
import { type getAddressParamsForCMS } from '@/features/cms/services/CMS/utils'
import { useCMSParamsWithoutSlug } from '@/features/cms/services/CMS/hooks'
import { useIsDataRightsRelatedQueryEnabled } from '@/features/account/services/DataPrivacy/hooks'
import { type Content } from '@/features/cms/components/CMS/types'

type StoreParamsAndAddressParams = {
  storeParams?: StoreParams
  addressParams?: ReturnType<typeof getAddressParamsForCMS>
}

export const CMS_RHP_SLUG = 'shop'
export const CMS_GHP_SLUG = 'shop/stores'

export const getCMSQueryKeySlug = (slug: string): Readonly<`CMS_${string}`> =>
  `CMS_${slug}`

export const cmsQueryOptions = ({
  slug,
  storeParams,
  addressParams,
}: StoreParamsAndAddressParams & {
  slug: string
}) => {
  return queryOptions({
    queryKey: [getCMSQueryKeySlug(slug), { slug, storeParams, addressParams }],
    queryFn: fetchCMSData,
  })
}

export const useQueryCMS = ({
  params,
  enabled,
  keepPreviousData,
}: {
  params: ReturnType<typeof useCMSParams>
  enabled: boolean
  keepPreviousData?: boolean
}) => {
  return useQuery({
    ...cmsQueryOptions(params),
    enabled: useIsDataRightsRelatedQueryEnabled(enabled),
    placeholderData: (prevData, previousQuery) =>
      keepPreviousData &&
      // we only want to keepPreviousData if the user has not changed their address
      // this prevents stale data from rendering on the GHP
      previousQuery?.queryKey[1].addressParams?.address_line_1 ===
        params.addressParams?.address_line_1
        ? prevData
        : undefined,
  })
}

export const catNavCmsQueryOptions = ({
  storeParams,
  addressParams,
}: StoreParamsAndAddressParams) =>
  queryOptions({
    queryKey: ['CMS_CAT_NAV', { storeParams, addressParams }],
    queryFn: fetchCMSCatNavData,
  })

export const useQueryCatNavCMS = () => {
  const { storeParams, addressParams } = useCMSParamsWithoutSlug()
  return useQuery({
    ...catNavCmsQueryOptions({ storeParams, addressParams }),
    enabled: Boolean(storeParams.store_location_id),
  })
}

export const useCMSParams = (slug: string) => {
  const cmsParamsWithoutSlug = useCMSParamsWithoutSlug()
  return {
    ...cmsParamsWithoutSlug,
    slug,
  }
}

export const useFetchDeferredHydration = (
  hydrationCallback: Content['hydration_callback']
) => {
  return useQuery({
    queryFn: () =>
      fetchDeferredHydration(
        hydrationCallback ?? {
          url: '',
          headers: {},
          body: {},
        }
      ),
    queryKey: ['DeferredCallback', hydrationCallback?.url ?? ''],
    enabled: !!hydrationCallback,
  })
}

const ProgressiveRevealShelf = 'Progressive reveal shelves' as const
export type ProgressiveRevealShelfQueryKey = [
  typeof ProgressiveRevealShelf,
  {
    storeParams: StoreParams
    productId: number | null
    searchQuery: string | string[]
    userId: number | null
  }
]

type ProgressiveRevealShelfQueryKeyParams = {
  storeParams: StoreParams
  productId: number | null
  searchQuery: string | string[]
  userId: number | null
}

export const getProgressiveRevealShelfQueryKey = ({
  storeParams,
  productId,
  searchQuery,
  userId,
}: ProgressiveRevealShelfQueryKeyParams): ProgressiveRevealShelfQueryKey => {
  return [
    ProgressiveRevealShelf,
    { storeParams, productId, searchQuery, userId },
  ] as const
}

export type ProgressiveRevealShelfQueryKeyReturnType = ReturnType<
  typeof getProgressiveRevealShelfQueryKey
>

export const orderPlacedStoresCarouselOptions = ({
  storeParams,
  addressParams,
}: StoreParamsAndAddressParams) =>
  queryOptions({
    queryKey: [
      'CMS_ORDER_PLACED_STORES_CAROUSEL',
      { storeParams, addressParams },
    ],
    queryFn: fetchOrderPlacedStoreCarousel,
  })

export const useQueryOrderPlacedStoresCarousel = ({
  orderStoreId,
  enabled,
}: {
  orderStoreId: number | undefined
  enabled: boolean
}) => {
  const { storeParams, addressParams } = useCMSParamsWithoutSlug()

  return useQuery({
    ...orderPlacedStoresCarouselOptions({ storeParams, addressParams }),
    enabled:
      Boolean(addressParams?.address_id) &&
      orderStoreId === storeParams.store_id &&
      enabled,
  })
}

const orderPlacedCMSShelvesOptions = ({
  storeParams,
  addressParams,
  exclude_product_ids,
}: StoreParamsAndAddressParams & {
  exclude_product_ids: number[]
}) =>
  queryOptions({
    queryKey: [
      'CMS_ORDER_PLACED_SHELVES',
      { storeParams, addressParams },
    ] as const,
    queryFn: (context) =>
      fetchOrderPlacedCMSShelves(context, exclude_product_ids),
  })

export const useQueryOrderPlacedCMSShelves = ({
  exclude_product_ids,
  enabled,
}: {
  exclude_product_ids: number[]
  enabled: boolean
}) => {
  const { storeParams, addressParams } = useCMSParamsWithoutSlug()

  return useQuery({
    ...orderPlacedCMSShelvesOptions({
      storeParams,
      addressParams,
      exclude_product_ids,
    }),
    enabled: Boolean(
      addressParams?.address_id && exclude_product_ids.length > 0 && enabled
    ),
  })
}

const zeroSearchResultsCMSShelvesOptions = ({
  storeParams,
  addressParams,
  query,
}: StoreParamsAndAddressParams & {
  query: string
}) =>
  queryOptions({
    queryKey: [
      'CMS_ZERO_RESULTS_SHELVES',
      { storeParams, addressParams, query },
    ] as const,
    queryFn: fetchZeroResultsCMSShelves,
  })

export const useQueryZeroSearchResultsCMSShelves = (
  query: string,
  enabled: boolean
) => {
  const { storeParams, addressParams } = useCMSParamsWithoutSlug()

  return useQuery({
    ...zeroSearchResultsCMSShelvesOptions({
      storeParams,
      addressParams,
      query,
    }),
    enabled,
  })
}
