import { type QueryClient } from '@tanstack/react-query'
import { type GetServerSidePropsContext } from 'next'
import {
  type CustomerBaseResponse,
  type User,
} from '@/features/account/services/User/types'
import { INVALID_STORE_SUPPLIED_MESSAGE } from '@/features/cms/services/CMS/utils'
import { shoppingStoresQueryOptions } from '@/features/shop/services/ShoppingStore/queries'
import { apiPatch } from '@/features/shared/utils/dataFetching'
import { logWarn } from '@/features/shared/utils/logger'
import { userQueryOptions } from '@/features/account/services/User/queries'

export const updateUserSelectedStoreSSR = async (
  user: User,
  ssrContext: GetServerSidePropsContext,
  queryClient: QueryClient,
  storeId: number
) => {
  // if there is a store_id and it doesn't match the current store for the user, update it
  if (storeId > 0 && user.store_id !== storeId) {
    //verify store is active/valid
    const stores = await queryClient.fetchQuery(
      shoppingStoresQueryOptions({
        address_id: user.default_shopping_address_id,
      })
    )
    const storeIsValid = stores.delivery_stores.some((s) => s.id === storeId)
    if (!storeIsValid) {
      logWarn(INVALID_STORE_SUPPLIED_MESSAGE)
      return user
    }

    //update the customer store_id
    const response = await apiPatch<CustomerBaseResponse>({
      config: {
        url: `api/v1/customers/${user.id}`,
        data: {
          default_shopping_address_id: user.default_shopping_address_id,
          store_id: storeId,
          order_delivery_type: 'standard',
        },
      },
      ssrContext,
      fetcherName: 'updateUserSelectedStore',
    })
    user = { ...user, ...response }
    queryClient.setQueryData(userQueryOptions.queryKey, user)
  }
  return user
}
